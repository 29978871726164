import React from 'react';
import Image, { StaticImageData } from "next/image";

import eSignDashboard from "@/shared/assets/images/e-sign-dashboard.png";

import Content1 from "@/shared/assets/landing-page/content-1.png";
import Content2 from "@/shared/assets/landing-page/content-2.png";
import Content3 from "@/shared/assets/landing-page/content-3.png";
import Content4 from "@/shared/assets/landing-page/content-4.png";

type Benefit = {
  title: string;
  description: string;
  image: StaticImageData;
};

const benefits: Benefit[] = [
  {
    title: "Customize Your Style",
    description:
      "Select from multiple signature styles or types that best fit your document, giving each a personalized touch.",
    image: Content1,
  },
  {
    title: "Upload & Share Files",
    description:
      "Effortlessly upload and share a wide range of file types with just a few clicks, making collaboration easy.",
    image: Content2,
  },
  {
    title: "Performance at a Glance",
    description:
      "Track your document progress effortlessly with real-time insights on completed, in-progress, declined, and cancelled tasks.",
    image: Content3,
  },
  {
    title: "Manage It All in One Place",
    description:
      "Stay organized by managing your documents in a single, secure location, accessible whenever you need them.",
    image: Content4,
  },
];

interface BenefitSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const BenefitsSection: React.FC<BenefitSectionProps> = ({ refSection }) => {
  return (
      <section
          className="pb-[120px] md:pb-[140px] px-4 dark:bg-dark bg-white"
          data-index="benefits"
          ref={refSection}
      >
          <div className="max-w-7xl mx-auto">
              <div className="flex justify-center mb-[32px]">
                  <div className="font-semibold flex justify-center w-fit items-center py-[10px] px-[27px] border-2 rounded-full border-primary text-primary">
                      Benefits
                  </div>
              </div>

              <h2 className="text-center mb-2">
                  <span
                      className="text-3xl md:text-4xl font-semibold"
                      style={{
                          background:
                              "linear-gradient(180deg, #0A1C2E 30%, #B9C8D9 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                          color: "transparent",
                          fontWeight: "bold",
                      }}
                  >
                      Effortless Document Solutions
                  </span>
              </h2>
              <p className="text-center text-gray-600 mb-9">
                  Sign with ease. Customize, share, and organize—all in one
                  place.
              </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 container mx-auto">
              {benefits.map((benefit, index) => (
                  <div
                      key={index}
                      style={{
                          background:
                              "linear-gradient(203.18deg, rgba(255, 255, 255, 0.25) 3.66%, rgba(29, 79, 132, 0.025) 59.79%)",
                      }}
                      className="rounded-lg border-[0.5px] border-gray-400"
                  >
                      {index === 0 || index === benefits.length - 1 ? (
                          <>
                              <div id="text">
                                  <div className="text-center pt-[32px] pb-[4px] font-medium text-xl">
                                      {benefit.title}
                                  </div>
                                  <div className="text-center w-[80%] mx-auto mb-[32px]">
                                      {benefit.description}
                                  </div>
                              </div>

                              <Image
                                  src={benefit.image}
                                  alt={benefit.title}
                                  width={400}
                                  height={300}
                                  className="w-full h-auto"
                              />
                          </>
                      ) : (
                          <>
                              <Image
                                  src={benefit.image}
                                  alt={benefit.title}
                                  width={400}
                                  height={300}
                                  className="w-full h-auto"
                              />

                              <div id="text">
                                  <div className="text-center pt-[32px] pb-[4px] font-medium text-xl">
                                      {benefit.title}
                                  </div>
                                  <div className="text-center w-[80%] mx-auto mb-[32px]">
                                      {benefit.description}
                                  </div>
                              </div>
                          </>
                      )}
                  </div>
              ))}
          </div>
      </section>
  );
};

export default BenefitsSection;
