import React from 'react';
import { Instagram, Youtube, Linkedin, Music } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import LogoBlue from "../../../../shared/assets/landing-page/logo-blue.svg";
import TiktokIcon from "../../../../shared/assets/landing-page/tiktok.svg";

type FooterSectionProps = {
    label?: string;
    link: string;
};

const menuItems: FooterSectionProps[] = [
    {
        label: "Home",
        link: "home",
    },
    {
        label: "Features",
        link: "features",
    },
    {
        label: "Benefits",
        link: "benefits",
    },
    {
        label: "Tutorial",
        link: "tutorial",
    },
];

const supportItems: FooterSectionProps[] = [
    {
        label: "Payment Methods",
        link: "/payment-methods",
    },
    {
        label: "Privacy Policy",
        link: "/privacy-policy",
    },
    {
        label: "Terms of Use",
        link: "/terms-of-use",
    },
    {
        label: "Contact",
        link: "/contact",
    },
];

type FooterProps = {
    handleClickMenu?: (section: string) => void;
};

const FooterSection: React.FC<FooterProps> = ({
    handleClickMenu = () => {},
}) => {
    return (
        <footer
            className="text-[#1A1A1A] py-12 2xl:px-4 xl:px-16 px-16"
            style={{
                background:
                    "linear-gradient(349deg, rgb(204 235 255) 1%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 99%)",
            }}
        >
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mb-8">
                    <div className="mb-[64px] mr-10">
                        <Image
                            src={LogoBlue}
                            // width={100}
                            height={30}
                            className="object-contain"
                            alt="logo"
                            priority
                        />
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-4 md:mb-12">
                            Menu
                        </h3>
                        <ul className="space-y-2">
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    <div
                                        onClick={() =>
                                            handleClickMenu(item.link)
                                        }
                                        className="transition-colors cursor-pointer"
                                    >
                                        {item.label}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold mb-4 md:mb-12">
                            Support
                        </h3>
                        <ul className="space-y-2">
                            {supportItems.map((item, index) => (
                                <li key={index}>
                                    <Link
                                        href={item.link}
                                        className="transition-colors"
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div></div>
                    <div></div>

                    <div>
                        <h3 className="mb-4 text-sm font-semibold">
                            Follow us on
                        </h3>
                        <div className="flex flex-row md:flex-col xl:flex-row space-x-4 md:space-x-0 xl:space-x-4 space-y-0 md:space-y-4 xl:space-y-0">
                            <a
                                href="#"
                                className="transition-colors p-3 rounded-full text-white w-fit"
                                aria-label="Instagram"
                                style={{
                                    background:
                                        "linear-gradient(14.76deg, #0177C1 17.16%, #1D4F84 46.08%, #233656 75%)",
                                }}
                            >
                                <Instagram className="w-6 h-6" />
                            </a>
                            {/* <a
                href="#"
                className="transition-colors p-3 rounded-full text-white w-fit"
                aria-label="YouTube"
                style={{
                  background:
                    "linear-gradient(14.76deg, #0177C1 17.16%, #1D4F84 46.08%, #233656 75%)",
                }}
              >
                <Youtube className="w-6 h-6" />
              </a> */}
                            <a
                                href="#"
                                className="transition-colors p-3 rounded-full text-white w-fit"
                                aria-label="LinkedIn"
                                style={{
                                    background:
                                        "linear-gradient(14.76deg, #0177C1 17.16%, #1D4F84 46.08%, #233656 75%)",
                                }}
                            >
                                <Linkedin className="w-6 h-6" />
                            </a>
                            <a
                                href="#"
                                className="transition-colors p-3 rounded-full text-white w-fit"
                                aria-label="TikTok"
                                style={{
                                    background:
                                        "linear-gradient(14.76deg, #0177C1 17.16%, #1D4F84 46.08%, #233656 75%)",
                                }}
                            >
                                <Image
                                    alt="Tiktok"
                                    className="h-6 w-6"
                                    src={TiktokIcon}
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="pt-8 flex justify-center items-center">
                    <p>
                        &copy; {new Date().getFullYear()}. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;
