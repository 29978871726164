import Image, { StaticImageData } from "next/image";
import React from "react";
import BackgroundImg from "@/shared/assets/landing-page/bg-feature.svg";

import EffortlesIcon from "@/shared/assets/landing-page/effortless.svg";
import EasyIcon from "@/shared/assets/landing-page/easy.svg";
import LargeIcon from "@/shared/assets/landing-page/large.svg";
import QrIcon from "@/shared/assets/landing-page/qr.svg";
import ReadyIcon from "@/shared/assets/landing-page/ready.svg";
import MateraiIcon from "@/shared/assets/landing-page/materai.svg";

type Feature = {
  icon: StaticImageData;
  title: string;
  description: string;
};

const features: Feature[] = [
    {
        icon: EffortlesIcon,
        title: "Effortless e-Signature",
        description:
            "Sign document quickly! Choose a style, upload an image, or use a digital stamp.",
    },
    {
        icon: EasyIcon,
        title: "Easy Document Sharing",
        description:
            "Use the “Share With Me” feature to quickly share documents with colleagues or clients in seconds.",
    },
    {
        icon: LargeIcon,
        title: "Large Storage",
        description:
            "Enjoy large document storage! All your signatures and documents are safely stored in the cloud.",
    },
    {
        icon: QrIcon,
        title: "QR Code Security",
        description:
            "Each signature comes with a unique QR code to ensure your documents are secure and authentic.",
    },
    {
        icon: ReadyIcon,
        title: "Ready-to-Use-Template",
        description:
            "Choose from a variety of professional templates to speed up your signing process.",
    },
    {
        icon: MateraiIcon,
        title: "E-Stamp & E-Materai",
        description:
            "Add e-materai and digital stamps directly in our editor to boost your document's validity",
    },
];

interface FeaturesSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ refSection }) => {
  return (
    <section
      className="pb-[40px] md:pb-[64px]"
      data-index="features"
      ref={refSection}
    >
      <div className="relative">
        <Image
          alt="Background"
          className="object-cover w-full h-[1520px] sm:h-[1420px] md:h-[1020px] lg:h-[820px]"
          src={BackgroundImg}
        />

        <div className="transform lg:-translate-y-1/2 left-1/2 -translate-x-1/2 lg:top-1/2 top-36 w-full absolute">
          <div className="flex justify-center mb-[32px]">
            <div className="font-semibold flex justify-center w-fit items-center py-[10px] px-[27px] border-2 rounded-full border-primary text-primary">
              Features
            </div>
          </div>

          <h2 className="text-center mb-2">
            <span
              className="text-3xl md:text-4xl font-semibold"
              style={{
                background:
                  "linear-gradient(180deg, #0A1C2E 30%, #B9C8D9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                color: "transparent",
                fontWeight: "bold",
              }}
            >
              Exciting features that make life easier
            </span>
          </h2>
          <p className="text-center text-gray-600 mb-12">
            Everything you need for digital signatures, hassle-free.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 container mx-auto mt-[30px] md:mt-[90px]">
            {features.map((item, index) => (
              <div
                key={index}
                className="col-span-1 rounded-2xl py-3 md:py-6 px-4 md:px-8 flex flex-col gap-3 md:gap-6 text-left backdrop-opacity-5 backdrop-invert bg-white/65 shadow-sm"
              >
                <div className="bg-slate-300 rounded-lg w-fit p-[4px]">
                  <Image
                    alt={item.title}
                    src={item.icon}
                    width={40}
                    height={40}
                  />
                </div>
                <h3 className="text-lg font-semibold">{item.title}</h3>
                <p className="text-sm text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
